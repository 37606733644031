<template>
  <div class="drangeForm">
    <el-dialog fullscreen custom-class="custom-class1" class="dialogClass" :show-close="false" :visible="showForm" @close="() => $emit('cancel',false)">
      <PageHeader :title="title" :border="false">
        <div class="top_view flex j-end">
          <el-button icon="el-icon-back" @click="() => $emit('cancel',false)">返回</el-button>
          <el-button type="primary" @click="submit">保存</el-button>
        </div>
      </PageHeader>

      <div class="main1">
        <!-- 左侧 -->
        <div class="left1">
          <!-- <div v-for="(item,index) in arr1" :key="index">
            <header class="weight p-tb-20">{{ item.Name }}</header>
            <draggable v-model="item.Value" :group="{name: `site`, put: true}" animation="100" drag-class="dragClass" ghost-class="ghostClass" chosen-class="chosenClass" @add="onEnd" />
          </div> -->
          <div v-for="(test,index) in arr2" :key="index" class="p-lr-20 OperationArea">
            <div class="Title"><span class="point" />{{ test.Name }}</div>
            <!-- <el-form size="small" label-width="0"> -->

            <draggable v-model="test.Value" animation="100" drag-class="dragClass" ghost-class="ghostClass" chosen-class="chosenClass" :group="{name: `${test.Name}-site`, put: true}" :move="onMove1" @start="isStart1($event,index)" @add="onAdd($event,index)">
              <template v-for="(item,ia) in test.Value" class="Keywordbox">
                <!-- <el-form-item v-if="!item.IsShow" :key="ia"> -->
                <div v-if="!item.IsShow" :key="ia" class="p-tb-5 Keywords">
                  <template v-if="test.Name === '企业证照' && item.Label !== '默认占位' ">
                    <div v-if="item.Info" class="flex j-center">
                      <div class="m-left-20">
                        {{ item.Info.CertificateName }}
                      </div>
                    </div>
                    <!-- 企业证照的占位 -->
                    <div v-else class="flex j-between" />
                  </template>

                  <!-- 相关业务 -->
                  <div>{{ item.Label === '默认占位' ? '' : item.Label }}</div>
                </div>
                <!-- </el-form-item> -->
              </template>
              <!-- <div v-for="item in test.Value" :key="item.Name" class="item">
                <div v-if="item.Label">{{ item.Label }}</div>
                  <div v-else>{{ item.Info.CertRemark }}</div

              </div> -->
            </draggable>
            <!-- </el-form> -->
          </div>

        </div>
        <!-- 右侧 -->
        <div
          class=" flex column p-tb-10 p-lr-10 Approval"
          style="background-color:#DDE3F1; flex: 1;height:auto"
        >
          <div v-for="(test,index) in arr1" :key="index" class="right1 p-lr-20">
            <div class="InfoTitleBox m-tb-20"><i class="el-icon-caret-right" /> {{ test.Name }}</div>
            <el-form :hide-required-asterisk="false" size="small" :label-width="test.Name === '企业证照' ? '0' : '130px'">
              <draggable
                v-model="test.Value"
                :group="{name: `${test.Name}-site`, put: true}"
                animation="300"
                drag-class="dragClass"
                ghost-class="ghostClass"
                chosen-class="chosenClass"
                style="display: flex;flex-wrap: wrap;"
                :move="onMove"
                @add="onAdd1($event,index)"
                @end="onEnd"
                @start="isStart($event,index)"
              >
                <template v-for="(item,ia) in test.Value">
                  <el-form-item v-if="item.IsShow" :key="ia" :required="item.Disabled" :style="{width:item.Colspan === 1 ? '30%' :item.Colspan === 2 ? '60%' : '100%',height:'auto'}" :label="item.IsShow ? item.Label === '默认占位' ? '' : item.Label : '' ">
                    <template v-if="test.Name === '企业证照'">

                      <div v-if="item.Info" class="flex j-center">
                        <el-image :src="item.Info.Url" style="width:200px;height:auto;" />
                        <div class="m-left-20">
                          {{ item.Info.CertificateName }}<br>
                          {{ item.Info.CertificateCode }}<br>
                          {{ item.Info.ValidTo }}<br>
                          {{ item.Info.CertRemark }}
                        </div>
                      </div>
                      <!-- 企业证照的占位 -->
                      <div v-else class="flex j-between" />
                    </template>
                    <div v-if="item.Label === '默认占位' || item.Info" style="height:20px;" />
                    <el-input v-else disabled autocomplete="off" />

                  </el-form-item>
                </template>

              </draggable>
            </el-form>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import PageHeader from '@/components/PageHeader'
export default {
  name: 'DrangeForm',
  components: {
    draggable,
    PageHeader
  },
  props: {
    title: {
      type: String,
      default: '企业首营审批表单'
    },
    indexN: {
      type: [Number, String],
      default: 0
    },
    showForm: {
      type: Boolean,
      default: false
    },
    idx: {
      type: [Number, String],
      default: 0
    },
    type: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      drag: false,
      arr1: [],
      arr2: [],
      sort_index: null,
      form_arr: [],
      key_: null,
      Value_ID: null,
      temp: {},
      IDX: null,
      dom_new: null,
      flag: null,
      testArr: []
    }
  },
  watch: {
    showForm: {
      handler(a) {
        const _this = this
        // 获取列表
        this.$api.GetFormJsonByType({
          IDX: this.idx,
          Type: this.type // 1 big
        }).then(res => {
          console.log(res)
          this.arr2 = []
          Object.assign(this.form_arr, JSON.parse(res.Message))
          this.arr1 = JSON.parse(res.Message)
          // this.IDX = JSON.parse(res.Message[this.indexN].IDX)
          const arr123 = []
          this.arr2 = this.form_arr
          this.arr1.forEach((item, index) => {
            this.arr2[index].Value = []
            item.Value.forEach((item2, index2) => {
              this.$set(item2, 'key_', index)
              if (!item2.IsShow) {
                arr123.push({ index, ID: item2.ID })
                _this.arr2[index].Value.push(item2)
                // item.Value.splice(index2, 1)
              }
            })
          })
          arr123.forEach((item) => {
            // _this.arr1[item.index].Value.splice(item.index2, 1)
            _this.arr1[item.index].Value.forEach((element, index3) => {
              if (element.ID === item.ID && !element.IsShow) {
                _this.arr1[item.index].Value.splice(index3, 1)
              }
            })
          })
          console.log('asdads', this.arr1)
          this.arr2.map((item, index) => {
            item.Value.map((item2, index2) => {
              this.$set(item2, 'key_', index)
            })
          })
          console.log('asdads', this.arr2)
          // this.arr1.map((item, index) => {
          //   item.Value.map((item2, index2) => {
          //     this.$set(item2, 'key_', index)
          //     // if (!item2.IsShow) {
          //     //   // this.arr2.push()
          //     // }
          //   })
          // })
        })
      },
      deep: true
    },
    temp: {
      handler(a) {
        console.log(a)
      },
      deep: true
    },
    arr2: {
      handler(a) {
        console.log('arr2', a)
        console.log('arr1', this.arr1)
        // a.map(item => {
        //   item.Value.map((item1, index1) => {
        //     if (item1.IsShow) {
        //       item.Value.splice(index1, 1)
        //     }
        //   })
        // })
        // this.$nextTick(() => {
        //   this.arr1 = a
        // })
        // this.arr1.map((item1, index1) => {
        //   item1.Value.map((item2, index2) => {
        //     if (item2.ID === a[index1].Value[index2].ID) {
        //       this.arr1[index1].Value.splice(index2, 1)
        //     }
        //   })
        // })
      },
      deep: true
    }
  },
  methods: {
    onMove1(e, originalEvent) {
      if (e.draggedContext.element.Label === '默认占位') return false
      if (e.relatedContext.element.key_ !== e.draggedContext.element.key_) return false
      // e.draggedContext.element.IsShow = 1
    },
    onMove(e, originalEvent) {
      // !==   e.draggedContext.element.IsShow
      if (e.draggedContext.element.Disabled) return false
      if (e.draggedContext.element.Label === '默认占位') return false
      // if (e.relatedContext.element.IsShow !== e.draggedContext.element.IsShow) return true
      if (e.relatedContext.element.key_ !== e.draggedContext.element.key_) return false
      // e.draggedContext.element.IsShow = 0
    },
    submit() {
      const arr = JSON.parse(JSON.stringify(this.arr1))
      console.log(this.arr2)
      // console.log(JSON.stringify(this.arr2))
      this.arr2.map((item, index) => {
        arr[index].Value = arr[index].Value.concat(...item.Value)
      })
      console.log(arr)
      this.$api.SaveApprovalProcessFormJson({
        IDX: this.idx,
        Type: this.type, // 1 big
        FormJson: JSON.stringify(arr)
      }).then(res => {
        if (res.RetCode === '0') return this.$message.success('表单数据保存成功')
      })
    },
    arrSet(arr) {
      return Array.from(new Set(arr))
    },
    isStart(e, index) {
      this.sort_index = index
    },
    isStart1(e, index) {
      console.log(e)
      // this.sort_index = index
      this.dom_new = this.arr2[index].Value[e.oldDraggableIndex]
      console.log(this.dom_new)
    },
    onRemove(e) {
      console.log(e)
    },
    onEnd(e) {
      // console.log(e)
      // console.log(this.arr1)
      // this.Value_ID = this.form_arr[this.sort_index].Value[e.oldDraggableIndex].ID
    },
    onAdd1(e) {
      console.log('arr2', this.arr2)
      console.log('arr1', this.arr1)
      // console.log(e)
      console.log(this.arr1)
      const abc = {}
      let i = 0
      this.arr1.forEach((item, index) => {
        item.Value.forEach((item1, index1) => {
          if (item1.ID === this.dom_new.ID) {
            item1.IsShow = 1
            i++
            abc.index = index
            abc.index1 = index1
          }
        })
      })
      if (i > 1) { this.arr1[abc.index].Value.splice(abc.index1, 1) }
      console.log('曹乐', abc)
      this.flag = false
      //  this.testArr = this.arr
      console.log('arr2', this.arr2)
    },
    onAdd(e) {
      // console.log(this.arr2)
      // console.log('onAdd', e)
      if (e.type === 'add') {
        this.arr2.map(item => {
          item.Value.map(item2 => {
            item2.IsShow = 0
          })
        })
        this.flag = true
        // console.log('this.form_arr[this.sort_index].Value[e.oldDraggableIndex]', this.form_arr[this.sort_index].Value[e.oldDraggableIndex])
        // this.Value_ID = this.form_arr[this.sort_index].Value[e.oldDraggableIndex].ID
        // this.form_arr[this.sort_index].Value[e.oldDraggableIndex].IsShow = 0
        // this.temp.old = e.oldDraggableIndex
      }
    }
  }
}
</script>
，
<style >
 .dialogClass .el-dialog__body{
    padding:0  !important;
  }
  .el-dialog__header{
    padding:0
  }
  .custom-class1{border-radius: 0!important;}
</style>
<style>
.drangeForm .is-disabled .el-input__inner  {
  cursor: auto;
}
</style>
<style lang='scss' scoped>
  .custom-class1{
        background: #fff;
        padding: 0;
        .top_view{
          width: 100%;
        }
        .main1{
             display: flex;
            justify-content: space-between;
            // height:calc(100vh - 20vh);
            .left1{
                width: 300px;
                // min-height: 50px;
                 border: solid 1px #eee;
                height: auto;
                background-color: #F5F6FA;
            }
            .right1{
              border-radius:5px ;
              height: auto;
              margin: 0 0 10px 0;
                background-color: #FFF;
            }
        }
  }
</style>
