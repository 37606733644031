<template>
  <div v-loading="showLoading" class="approval-process">
    <template v-if="$minCommon.checkMeau('spsznodeview', userBottons)">
      <div v-for="(i, index) in list" :key="index">
        <PageHeader
          :title="i.ProcessTypeString"
        >
          <el-button v-if="$minCommon.checkMeau('spszformsetting', userBottons)" class="ModelBttton-white-30" type="info" @click="formSet(i, index)">表单设置</el-button>
        </PageHeader>
        <div class="main_dom flex j-start a-center">
          <div class="list flex">
            <div
              v-for="(item, index1) in i.nodeList"
              :key="item.IDX"
              class="flex a-center"
            >
              <div :class="isCreate === index ? 'item position' : 'item'">
                <div :class="'header-' + item.NodeType" @click.stop="showDraw(item)">{{ item.NodeName }}</div>
                <div class="mid f12" @click.stop="showDraw(item)">
                  <el-tooltip placement="bottom">
                    <div slot="content">
                      <div v-for="user in item.nodeUserList" :key="user.IDX">
                        {{ user.Name }}
                      </div>
                    </div>
                    <i class="el-icon-s-custom f16" style="color:#ccc" />
                  </el-tooltip>
                </div>

                <div class="min-border-top" @click.stop="showDraw(item)" />
                <div
                  v-if="index1 !== 0 && $minCommon.checkMeau('spszdeletenode', userBottons)"
                  class="item_close cursor"
                  @click="del(item, index, index1)"
                >
                  <i class="el-icon-close" />
                </div>
                <div
                  v-if="index1 !== 0"
                  class="item_copyX cursor"
                  @click="copyX(index, index1)"
                >
                  <el-tooltip v-if="$minCommon.checkMeau('spszshear', userBottons)" placement="bottom">
                    <div slot="content" class="f12">
                      剪切
                    </div>
                    <i class="el-icon-scissors f12" />
                  </el-tooltip>
                </div>
                <div
                  v-if="index1 !== 0"
                  class="item_copyV cursor"
                  @click="copyC(index, index1)"
                >
                  <el-tooltip v-if="$minCommon.checkMeau('spszcopy', userBottons)" placement="bottom">
                    <div slot="content" class="f12">
                      复制
                    </div>
                    <i class="el-icon-document-copy f12" />
                  </el-tooltip>
                </div>
                <div
                  v-if="index1 !== 0"
                  class="item_setForm cursor"
                  @click="setForm(item, index)"
                >
                  <el-tooltip placement="bottom">
                    <div slot="content" class="f12">
                      设置表单
                    </div>
                    <i class="el-icon-brush f12" />
                  </el-tooltip>
                </div>
              </div>
              <div
                v-if="isCreate.index === index && isCreate.index1 === index1"
                class="line"
              />
              <div
                v-if="isCreate.index === index && isCreate.index1 === index1"
                class="createDom"
              >
                <div class="header" />
                <div class="p-lr-10">
                  <span class="f12 p-bottom-5" style="display:inline-block">节点名称</span>
                  <el-input v-model="form.NodeName" size="mini" />
                </div>
                <div class="p-lr-10 flex column p-top-10">
                  <span class="f12">负责人</span>
                  <div>
                    <el-tooltip placement="bottom">
                      <div slot="content">
                        <div v-for="user in set_form_value.nodeUserList" :key="user.IDX">
                          {{ user.Name }}
                        </div>
                      </div>
                      <i class="el-icon-s-custom f16" style="color:#ccc" />
                    </el-tooltip>
                    <i class="el-icon-circle-plus-outline p-top-10 f24 p-left-10" @click="addUser" />
                  </div>
                </div>
                <div class="p-lr-10 flex p-top-10 m-top-10 j-end t_line">
                  <el-button
                    size="mini"
                    :disabled="loading"
                    @click="cancelCreate"
                  >取消</el-button>
                  <el-button
                    size="mini"
                    type="primary"
                    :loading="loading"
                    @click="create(index, index1)"
                  >创建</el-button>
                </div>
              </div>
              <div class="line flex j-center">
                <div class="add" @click="show(index, index1)">
                  <el-popover
                    v-model="item.showPopover"
                    placement="bottom"
                    trigger="hover"
                    popper-class="test_pop_view"
                  >
                    <div
                      v-if="$minCommon.checkMeau('spszapprove', userBottons)"
                      class="cursor"
                      @click="createDom(index, index1, 1)"
                    >
                      <span><i class="el-icon-s-check p-right-5" />审批节点</span>
                    </div>
                    <div
                      v-if="$minCommon.checkMeau('spszcopyto', userBottons)"
                      class="cursor"
                      @click="createDom(index, index1, 2)"
                    >
                      <span><i class="el-icon-s-promotion p-right-5" />抄送节点</span>
                    </div>
                    <div
                      v-if="$minCommon.checkMeau('spszpaste', userBottons)"
                      class="cursor"
                      @click="copyV(index, index1)"
                    >
                      <span><i class="el-icon-s-claim p-right-5" />粘贴节点</span>
                    </div>
                    <i slot="reference" class="el-icon-circle-plus-outline " />
                  </el-popover>
                </div>
              </div>
            </div>
            <div class="flex a-center ">
              <div class="end select_none">
                结束
              </div>
              <div style="width:30px;height:30px" />
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- <PageHeader title="产品首营审批设置" center />
    <div class="main_dom flex j-start a-center" />
    <PageHeader title="企业资料修改审批设置" center />
    <div class="main_dom flex j-start a-center" /> -->
    <DrangeForm
      :idx="idx_"
      :type="type_"
      :show-form="showForm"
      :index-n="infoListindex"
      :title="title_form"
      @cancel="() => (showForm = false)"
    />
    <el-drawer
      :visible.sync="drawer"
      :with-header="false"
      size="40%"
    >
      <div class="top_header flex a-center j-between p-lr-10">
        <div class="flex a-center" style="color:#fff;">
          <h4 v-if="set_name" class="p-left-10">{{ set_form_value.NodeName }}</h4>
          <el-input v-else v-model="set_form_value.NodeName" @blur="isblur" />
          <i class="el-icon-edit p-left-10" @click="() => set_name = false" />
        </div>
        <div class="flex a-center f12" style="color:#fff;">
          <div>节点类型</div>
          <el-popover
            v-model="popover"
            placement="bottom"
            trigger="manual"
            :width="300"
            popper-class="test_pop_view"
          >
            <div class="p-tb-10 b_line m-bottom-10">节点类型切换</div>
            <div class="one one_a flex j-between a-center" @click="setNodetype(1)">
              <div class="flex column  p-lr-10">
                <span style="color:#fff;font-weight: bolder;">审批节点</span>
                <span style="color:#fff" class="f12">审批节点可以对进行通过/不通过操作</span>
              </div>
              <i class="el-icon-s-check p-right-20 f30" style="color:#fff" />
            </div>
            <div class="one one_b m-top-10 flex j-between a-center" @click="setNodetype(2)">
              <div class="flex column  p-lr-10">
                <span style="color:#fff;font-weight: bolder;">抄送节点</span>
                <span style="color:#fff" class="f12">可将数据抄送给指定用户</span>
              </div>
              <i class="el-icon-position p-right-10 f30" style="color:#fff" />
            </div>
            <div slot="reference" class="p-lr-20 cursor" @click="() => popover = !popover">
              {{ set_form_value.NodeType === 1 ? '审批节点' : '抄送节点' }} <i class="el-icon-arrow-down" />
            </div>
          </el-popover>

          <i class="el-icon-delete p-left-10 f16" @click="del_detail" />
        </div>
      </div>
      <div class="mid_view flex j-between a-center p-lr-20">
        <div class="left">
          <p class="weight">负责人</p>
          <div>
            <el-popover
              placement="bottom"
              trigger="hover"
              :width="50"
              popper-class="test_pop_view"
            >
              <div v-for="(item, index) in set_form_value.nodeUserList " :key="item.IDX" class="flex j-between a-center">
                <span>{{ item.Name }}</span>
                <span class="cursor" @click="delUser(index)">x</span>
              </div>
              <i slot="reference" class="el-icon-user-solid p-right-20 f30" style="color:#ccc;font-size:30px" />
            </el-popover>
            <i class="el-icon-circle-plus-outline" @click="addUser(true)" />
          </div>
        </div>
        <div class="right min-border-left p-left-20">
          <p class="weight">审批类型</p>
          <div>
            <el-select v-model="set_form_value.ApprovalType" :disabled="set_form_value.disaled" size="mini" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
      </div>
      <p class="min-border-bottom  p-lr-20 weight p-bottom-20">基础设置</p>
      <div class="main_view flex column">
        <div class="flex j-between a-center p-lr-20 item min-border-bottom p-bottom-20">
          <div class="flex a-center">
            <i class="el-icon-folder-opened" />
            <p class="p-left-10">流程日志</p>
          </div>
          <el-switch
            v-model="set_form_value.AllowJournalView"
            active-color="#4DA6FF"
            inactive-color="#dddddd"
          />
          <span class="desc f12" style="color:#ccc">允许查看流程日志</span>
        </div>
        <div class="flex j-between a-center p-lr-20 item min-border-bottom p-bottom-20">
          <div class="flex a-center">
            <i class="el-icon-chat-line-round" />
            <p class="p-left-10">留言</p>
          </div>
          <el-switch
            v-model="set_form_value.AllowLeaveMessage"
            active-color="#4DA6FF"
            inactive-color="#dddddd"
          />
          <span class="desc f12" style="color:#ccc">节点负责人可以留言且查看记录</span>
        </div>
        <div class="flex j-between a-center p-lr-20 item min-border-bottom p-bottom-20">
          <div class="flex a-center">
            <i class="el-icon-edit" />
            <p class="p-left-10">是否需要修改</p>
          </div>
          <el-switch
            v-model="set_form_value.AllowEdit"
            active-color="#4DA6FF"
            inactive-color="#dddddd"
          />
          <span class="desc f12" style="color:#ccc">修改表单内容</span>
        </div>
      </div>
      <div v-if="$minCommon.checkMeau('spsznodeedit', userBottons)" class="caozuo t-right p-tb-20 p-right-20">
        <el-button type="primary" @click="setNodeSave">保存</el-button>
      </div>

    </el-drawer>
    <el-dialog class="DepartmentBox" title="添加负责人" center :visible.sync="seletUserVisible" width="25%" :close-on-click-modal="false" :show-close="false">
      <!-- <PageHeader :border="false" title="批量替换业务员" center /> -->
      <div v-loading="isDataCome" class="rolebody">
        <el-tabs v-model="selectUseractiveName" :before-leave="isReject" @tab-click="selectUseractiveNamehandleClick">
          <el-tab-pane label="成员" name="user" />
          <el-tab-pane label="部门" name="dept" />
          <el-tab-pane label="角色" name="role" />
        </el-tabs>
        <div v-if="selectUseractiveName === 'user'" class="selectuser">
          <el-input
            v-model="selectUserkeywords"
            size="small"
            placeholder="搜索成员"
            prefix-icon="el-icon-search"
          />
          <div class="selectUserTree">
            <el-tree
              ref="allusertree"
              :data="connuserlist"
              node-key="value"
              highlight-current
              :filter-node-method="alluserfilterNode"
            >
              <span slot-scope="{ node, data }" style="width:100%">
                <span class="roleSpan"><div class="deptSpandiv"><i class="el-icon-user" /></div></span>
                <span class="deptnamespan" @click="checkeduser(data.ischecked,data.value,data,true)">
                  {{ node.label }}</span>
                <!-- nodeType- 0：部门 1：员工 -->
                <span v-if="data.nodeType === 1" class="deptcountspan"><el-checkbox v-if="data.nodeType === 1 && data.IDX > 0" v-model="data.ischecked" @change="checkeduser(data.ischecked,data.value,data)" /></span>
              </span>
            </el-tree>
          </div>
        </div>
        <div v-if="selectUseractiveName === 'dept'" class="selectuser">
          <el-tree
            ref="tree"
            :data="selectUserDeptData"
            node-key="value"
            highlight-current
          >
            <span slot-scope="{ node, data }" style="width:100%">
              <span class="roleSpan"><div class="deptSpandiv"><i class="el-icon-user" /></div></span>
              <span class="deptnamespan" @click="checkedDept(data.ischecked,data.value,data,true)">
                {{ node.label }}</span>
              <span class="deptcountspan"><el-checkbox v-model="data.ischecked" @change="checkedDept(data.ischecked,data.value,data)" /></span>
            </span>
          </el-tree>
        </div>
        <div v-if="selectUseractiveName === 'role'" class="selectuser">
          <el-tree
            ref="tree"
            :data="selectUserroleData"
            node-key="value"
            highlight-current
          >
            <span slot-scope="{ data }" style="width:100%">
              <span class="roleSpan"><div class="deptSpandiv"><i class="el-icon-user" /></div></span>
              <span class="deptnamespan">
                {{ data.RoleName }}</span>
              <!-- data.ischecked,data.value, -->
              <span class="deptcountspan"><el-checkbox v-model="data.ischecked" @change="checkedRole(data.ischecked,data)" /></span>
            </span>
          </el-tree>
        </div>
      </div>
      <el-divider />
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelDraw">取 消</el-button>
        <el-button type="primary" @click="doconnuser">确 定</el-button>
        <el-button type="primary" @click="$refs.addUser.openaddUser()">新增成员</el-button>
      </div>
    </el-dialog>
    <addUser ref="addUser" />
  </div>
</template>
<script>
import PageHeader from '@/components/PageHeader'
import DrangeForm from '@/components/drangeForm'
import $userRequest from '@/request/contactsapi.js'
import root from '@/minxin/root'
import addUser from '@/components/addUser'
export default {
  name: 'Approval',
  components: {
    PageHeader,
    DrangeForm,
    addUser
  },
  mixins: [root],
  data() {
    return {
      popover: false,
      root_name: 'spsz',
      root_msg: '审批设置',
      title_form: '',
      isDataCome: true,
      // ================================================= 选择人员 begin  =================================================
      // ================================================= 选择人员 begin ==================================================
      seletUserVisible: false,
      selectUseractiveName: 'user', // 选择人员tab切换
      isshow: false, // 控制 部门和角色选择是否显示
      selectUserkeywords: '', // 搜索成员关键字
      connuserlist: [], // 成员list
      fullscreenLoading: false,
      selectUserDeptData: [], // 部门数据
      selectUserroleData: [], // 角色数据
      checkeduserIdList: [], // 选中的列表
      checkedDeptList: [], // 选中的部门
      checkedRoleList: [], // 选中的角色
      // ================================================= 选择人员 end  =================================================
      // ================================================= 选择人员 end ==================================================
      set_name: true,
      set: false,
      liucheng: false,
      liuyan: false,
      options: [{
        value: 1,
        label: '或签（一名负责人通过或拒绝即可）'
      }, {
        value: 2,
        label: '会签（需要所有负责人通过或拒绝即可）'
      }],
      value_a: '',
      showLoading: true,
      type_: null,
      idx_: null,
      infoListindex: 0,
      showForm: false,
      loading: false,
      showPopover: false,
      isCreate: { index: null, index1: null },
      id: null,
      domName: '',
      tempItem: {},
      drawer: false,
      form: {
        IDX: 0,
        ApprovalProcessID: null,
        NodeName: '',
        NodeType: 2,
        ApprovalType: 2,
        AllowJournalView: 1,
        AllowLeaveMessage: 1,
        AllowEdit: 1,
        FormJson: '',
        ProcessType: 1,
        UserJson: '',
        sort: 1
      },
      list: [
        { name: '申请人', id: 0, person: '小明', showPopover: false }
        // { name: '抄送人', id: 2, person: '小王' },
        // { name: '审批人', id: 3, person: '小红' }
      ],
      set_form_value: {
        IDX: '',
        NodeName: '',
        NodeType: '',
        ApprovalType: '',
        AllowJournalView: '',
        AllowLeaveMessage: '',
        AllowEdit: '',
        UserJson: '',
        nodeUserList: []
      },
      nodeUserListTemp: [],
      flag: ''
    }
  },
  watch: {
    selectUserkeywords(val) {
      this.$refs.allusertree.filter(val)
    },
    flag: {
      handler(a) {
      //   if (this.checkeduserIdList.length > 0) {
      //   arr = this.checkeduserIdList
      //   Type = 1
      // }
      // if (this.checkedDeptList.length > 0) {
      //   arr = this.checkedDeptList
      //   Type = 2
      // }
      // if (this.checkedRoleList.length > 0) {
      //   arr = this.checkedRoleList
      //   Type = 3
      // }//  checkeduserIdList: [], // 选中的列表
      // checkedDeptList: [], // 选中的部门
      // checkedRoleList: [], // 选中的角
        this.checkeduserIdList = []
        this.checkedDeptList = []
        this.checkedRoleList = []
        if (a) {
          console.log(a)
          return this.set_form_value.nodeUserList = this.nodeUserListTemp
        }
      },
      deep: true
    }
  },
  mounted() {
    // const loadingInstance = this.$loading()
    // setTimeout(() => {
    //   loadingInstance.close()
    // }, 2000)
    this.GetApprovalProcessList()
  },
  methods: {
    addUSER() {
      this.seletUserVisible = true
    },
    // 负责人
    addUser(isSet) {
      console.log('弹吧')
      this.nodeUserListTemp = this.set_form_value.nodeUserList
      this.set_form_value.nodeUserList = []
      this.flag = ''
      if (isSet) this.set_form_value.UserJson = ''
      this.seletUserVisible = true
      // 人员
      this.getSelectUserQueryListOfDept()
      this.getRoledata()
      this.getdeptList()
    },
    // ================================================= 选择人员 begin  =================================================
    // ================================================= 选择人员 begin ==================================================
    getdeptList() {
      const _this = this
      $userRequest.GetDeptList().then(res => {
        if (res.RetCode === '0') {
          _this.selectUserDeptData = res.Message
          this.isDataCome = false
        }
      })
    },
    getRoledata() {
      const _this = this
      $userRequest.GetRoleList({ pageIndex: 1, pageSize: -1 }).then(res => {
        console.log(res)
        if (res.RetCode === '0') {
          _this.selectUserroleData = res.Message
          this.isDataCome = false
        }
      })
    },
    isReject(activeName, oldActiveName) {
      console.log(activeName, oldActiveName)
      if (activeName === 'user') {
        if (this.checkedDeptList.length > 0 || this.checkedRoleList.length > 0) {
          this.$message.info('只能选择一个类型')
          return false
        }
      }
      if (activeName === 'dept') {
        if (this.checkeduserIdList.length > 0 || this.checkedRoleList.length > 0) {
          this.$message.info('只能选择一个类型')
          return false
        }
      }
      if (activeName === 'role') {
        if (this.checkeduserIdList.length > 0 || this.checkedDeptList.length > 0) {
          this.$message.info('只能选择一个类型')
          return false
        }
      }
    },
    selectUseractiveNamehandleClick(val) {
      console.log(val)
      // checkeduserIdList 人员
      //  checkedDeptList: [], // 选中的部门
      // checkedRoleList: [],  角色name="user" />
    },
    // 初始化选择人员数据
    oprenSelectContractUser() {
      const _this = this
      _this.getSelectUserQueryListOfDept()
      _this.seletUserVisible = true
    },
    // 获取选择人员时 ，成员列表
    getSelectUserQueryListOfDept() {
      const _this = this
      _this.fullscreenLoading = true
      _this.connuserlist = []
      $userRequest.SelectUserQueryListOfDept().then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.connuserlist = res.Message
        }
        this.isDataCome = false
      })
    },
    // 过滤树形数据
    alluserfilterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    // 选中人员
    checkeduser(ischecked, val, item, ii) {
      console.log(ischecked, val, item, ii)
      // if (ii && ischecked) {
      //   item.ischecked = true
      // }
      // 默认没选中
      if (ii && !ischecked && item.nodeType === 1 && item.IDX) {
        item.ischecked = true
      }
      if (ii && ischecked && item.nodeType === 1 && item.IDX) {
        item.ischecked = false
      }
      if (item.ischecked) {
        this.checkeduserIdList.push(item)
      }
      if (!item.ischecked) {
        this.checkeduserIdList.map((item1, index) => {
          if (item1.IDX === item.IDX) {
            this.checkeduserIdList.splice(index, 1)
          }
        })
      }
      console.log('this.checkeduserIdList', this.checkeduserIdList)
    },
    // 部门
    checkedDept(ischecked, val, item, ii) {
      console.log(ischecked, val, item, ii)
      // 默认没选中
      if (ii && !ischecked && item.IDX) {
        item.ischecked = true
      }
      if (ii && ischecked && item.IDX) {
        item.ischecked = false
      }
      if (item.ischecked) {
        this.checkedDeptList.push(item)
      }
      if (!item.ischecked) {
        this.checkedDeptList.map((item1, index) => {
          if (item1.IDX === item.IDX) {
            this.checkedDeptList.splice(index, 1)
          }
        })
      }
    },
    // 角色
    checkedRole(ischecked, item, ii) {
      console.log(item, ii)
      // 默认没选中
      if (ii && !ischecked && item.IDX) {
        item.ischecked = true
      }
      if (ii && ischecked && item.IDX) {
        this.$nextTick(() => {
          item.ischecked = false
        })
      }
      if (item.ischecked) {
        this.checkedRoleList.push(item)
      }
      if (!item.ischecked) {
        this.checkedRoleList.map((item1, index) => {
          if (item1.IDX === item.IDX) {
            this.checkedRoleList.splice(index, 1)
          }
        })
      }
    },
    cancelDraw() {
      this.seletUserVisible = false
      this.flag = true
    },
    // 确认选中人员
    doconnuser() {
      this.nodeUserListTemp = []
      this.flag = false
      // const _this = this
      //  checkeduserIdList: [], // 选中的列表
      // checkedDeptList: [], // 选中的部门
      // checkedRoleList: [], // 选中的角色
      this.form.UserJson = ''
      this.set_form_value.nodeUserList = []
      let arr = []
      const str = [] // "[{ 'ID': 1866, 'Type': 1 }]"
      const brr = []
      let Type = null
      if (this.checkeduserIdList.length > 0) {
        arr = this.checkeduserIdList
        Type = 1
      }
      if (this.checkedDeptList.length > 0) {
        arr = this.checkedDeptList
        Type = 2
      }
      if (this.checkedRoleList.length > 0) {
        arr = this.checkedRoleList
        Type = 3
      }
      arr.map(item => {
        str.push({ ID: item.IDX, Type: Type })
        console.log(item)
        brr.push({ IdentityID: item.IDX, IdentityType: Type, Name: item.label ? item.label : item.RoleName })
      })
      // this.set_form_value.nodeUserList = []
      // this.set_form_value.nodeUserList = this.set_form_value.nodeUserList.concat(...brr)
      this.set_form_value.nodeUserList = brr

      if (this.set_form_value.nodeUserList.length) {
        const arr = this.set_form_value.nodeUserList.filter(item => item.IdentityType !== 1)
        if (arr.length > 0) {
          console.log(arr)
          this.set_form_value.disaled = true
          this.set_form_value.ApprovalType = 1
        } else {
          this.set_form_value.disaled = false
          // this.set_form_value.ApprovalType = 1
        }
      }
      console.log('this.set_form_value.nodeUserList', this.set_form_value.nodeUserList)
      this.form.UserJson = JSON.stringify(str)
      this.seletUserVisible = false
      // this.$message.success('添加成功')
    },
    // ================================================= 选择人员 end  =================================================
    // ================================================= 选择人员 end ==================================================
    // 设置节点名称失去焦点
    isblur() {
      this.set_name = true
    },
    // 取消创建
    cancelCreate() {
      this.checkedDeptList = []
      this.checkedRoleList = []
      this.checkeduserIdList = []
      this.isCreate = { index: null, index1: null }
      this.form.NodeName = ''
      this.set_form_value.nodeUserList = []
    },
    // 保存节点
    setNodeSave() {
      const list = []
      this.set_form_value.nodeUserList.map(item => {
        list.push({
          'id': item.IdentityID,
          'type': item.IdentityType
        })
      })
      this.set_form_value.AllowJournalView = this.set_form_value.AllowJournalView ? 1 : 0
      this.set_form_value.AllowLeaveMessage = this.set_form_value.AllowLeaveMessage ? 1 : 0
      this.set_form_value.AllowEdit = this.set_form_value.AllowEdit ? 1 : 0
      this.set_form_value.UserJson = JSON.stringify(list)
      // delete this.set_form_value.nodeUserList
      console.log(this.set_form_value)
      this.$api.SaveApprovalProcessNode(this.set_form_value).then((res) => {
        this.drawer = false
        if (res.RetCode === '0') {
          this.$message.success('节点修改成功')
          this.$nextTick(() => {
            this.GetApprovalProcessList()
          })
          return
        }
        this.$message.error('节点修改失败')
      })
    },
    // 设置审批节点
    setNodetype(type) {
      this.set_form_value.NodeType = type
      this.popover = false
      // this.$message.success('节点类型设置成功')
    },
    // 详情删除节点
    del_detail() {
      this.$api
        .DeleteApprovalProcessNode({
          IDX: this.set_form_value.IDX
        })
        .then((res) => {
          this.drawer = false
          if (res.RetCode === '0') {
            this.GetApprovalProcessList()
            this.$message.success('删除节点成功')
            return
          }
          return this.$message.error('删除节点失败')
        })
    },
    // 展示抽屉
    showDraw(item) {
      console.log(item)
      if (!item.NodeType) return
      for (const key in this.set_form_value) {
        this.set_form_value[key] = item[key]
      }
      if (item.nodeUserList.length) {
        const arr = item.nodeUserList.filter(item => item.IdentityType !== 1)
        if (arr.length > 0) {
          console.log(arr)
          this.set_form_value.disaled = true
          this.set_form_value.ApprovalType = 1
        }
        this.set_form_value.ApprovalType = 1
      }

      this.set_form_value.AllowJournalView = !!this.set_form_value.AllowJournalView
      this.set_form_value.AllowLeaveMessage = !!this.set_form_value.AllowLeaveMessage
      this.set_form_value.AllowEdit = !!this.set_form_value.AllowEdit
      console.log(this.set_form_value)

      this.drawer = true
    },
    setForm(i, index) {
      console.log(i)
      this.$nextTick(() => {
        this.title_form = this.list[index].ProcessTypeString
        this.showForm = true
        this.idx_ = i.IDX
        this.type_ = 2
      })
    },
    // 显示拖拽
    formSet(i, index) {
      console.log(i)
      this.showForm = true
      this.title_form = i.ProcessTypeString
      // this.infoList = JSON.parse(this.list[index].FormJson)
      this.infoListindex = index
      this.idx_ = i.IDX
      this.type_ = 1
    },
    // 修改、删除负责人
    delUser(index) {
      this.set_form_value.nodeUserList.splice(index, 1)
      console.log(this.set_form_value.nodeUserList)
    },
    // 获取列表
    GetApprovalProcessList() {
      this.$api
        .GetApprovalProcessList()
        .then((res) => {
          console.log(res)
          if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
          this.$nextTick(() => {
            this.list = res.Message
            this.showLoading = false
          })
        })
        .catch((err) => {
          this.$message.error(err)
          this.showLoading = false
        })
    },
    // 剪切节点
    copyX(index, index1) {
      this.copyC(index, index1, true)
      this.del(
        { IDX: this.list[index].nodeList[index1].IDX },
        index,
        index1,
        true
      )
      // this.list[index].nodeList.splice(index1, 1)
    },
    // 复制节点
    copyC(index, index1, copyX) {
      console.log(this.list[index])
      // this.tempItem = this.list[index].nodeList[index1]
      Object.assign(this.tempItem, this.list[index].nodeList[index1])
      this.$message.success(copyX ? '剪切成功' : '复制成功')
    },
    // 粘贴节点
    copyV(index, index1) {
      if (!this.tempItem.NodeType) {
        return this.$message.error('没有可粘贴的节点')
      }
      this.tempItem.IDX = 0
      // const data_obj =  this.tempItem
      this.tempItem.sort = index1
      console.log(this.tempItem)
      this.$api.SaveApprovalProcessNode(this.tempItem).then((res) => {
        console.log(res)
        this.isCreate = { index: null, index1: null }
        this.loading = false
        if (res.RetCode === '0') {
          this.$message.success('节点粘贴成功')
          this.list[index].nodeList.splice(index1 + 1, 0, {
            ApprovalProcessID: res.Message.ApprovalProcessID,
            IDX: res.Message.NodeID,
            NodeName: this.tempItem.NodeName,
            NodeType: this.tempItem.NodeType,
            nodeUserList: this.tempItem.nodeUserList,
            showPopover: false
          })
          this.tempItem = {}
          return
        }
        this.$message.error('节点粘贴失败')
      })
      this.list[index].nodeList[index1].showPopover = false
    },
    show(index, index1) {
      console.log('12312', index, index1)
      this.$nextTick(() => {
        this.list[index].nodeList[index1].showPopover = !this.list[index].nodeList[index1].showPopover
      })
    },
    // 创建节点
    createDom(index, index1, id) {
      // this.list.splice(index + 1, 0, { isCreate: true, id })
      this.isCreate = { index, index1 }
      this.id = id
      this.form.NodeType = id
      this.form.ProcessType = this.list[index].ProcessType
      this.list[index].nodeList[index1].showPopover = false
      console.log(this.list)
    },
    // 删除节点
    del(item, index, index1, COPYX) {
      console.log(item)
      this.DeleteApprovalProcessNode(item.IDX, index, index1, COPYX)
      this.set_form_value.nodeUserList = []
      this.form.nodeUserList = []
      this.form.UserJson = ''
    },
    // 删除节点方法
    DeleteApprovalProcessNode(IDX, index, index1, COPYX) {
      this.$api
        .DeleteApprovalProcessNode({
          IDX
        })
        .then((res) => {
          console.log(res)
          if (res.RetCode === '0') {
            COPYX ? '' : this.$message.success('删除节点成功')

            this.list[index].nodeList.splice(index1, 1)
          }
        })
    },
    // 生成节点
    create(index, index1) {
      console.log(this.list[index].nodeList[index1 + 1])
      if (!this.form.NodeName) return this.$message.error('节点名称为非')
      if (this.list[index].nodeList[index1 + 1]) {
        if (this.form.NodeType === 2 && this.list[index].nodeList[index1 + 1].NodeType === 2) {
          // 下一个
          return this.$message.error('抄送节点不可连续设置')
        }
      }
      if (this.form.NodeType === 2 && this.list[index].nodeList[index1].NodeType === 2) {
        // 上一个
        return this.$message.error('抄送节点不可连续设置')
      }

      this.loading = true
      this.form.ApprovalProcessID = this.list[index].IDX
      this.form.sort = this.list[index].nodeList[index1].Sort
      this.$api.SaveApprovalProcessNode(this.form).then((res) => {
        console.log(res)
        this.isCreate = { index: null, index1: null }
        this.loading = false
        if (res.RetCode === '0') {
          this.$message.success('创建节点成功')
          this.$api
            .GetApprovalNodeInfo({
              NodeID: res.Message.NodeID
            })
            .then((res) => {
              console.log(res)
              this.list[index].nodeList.splice(index1 + 1, 0, res.Message)
            })
          // this.list[index].nodeList.splice(index1 + 1, 0, {
          //   ApprovalProcessID: res.Message.ApprovalProcessID,
          //   IDX: res.Message.NodeID,
          //   NodeName: this.form.NodeName,
          //   NodeType: this.form.NodeType,
          //   nodeUserList: this.form.nodeUserList,
          //   showPopover: false
          // })
          this.form.NodeName = ''
          this.set_form_value.nodeUserList = []
          this.checkedDeptList = []
          this.checkedRoleList = []
          this.checkeduserIdList = []
          return
        }
        this.set_form_value.nodeUserList = []
        this.$message.error('创建节点失败:' + res.RetMsg)
      })

      // SaveApprovalProcessNode
    }
  }
}
</script>
<style lang="scss" scoped>

/* =======================选择人员 begin   =======================*/
/* =======================选择人员 begin   =======================*/

.deptnamespan {
  font-size: 12px;
  line-height: 20px;
  float: left;
}
.selectuserrole {
    width: 100%;
    height: 30px;
  }
.selectUserTree {
    margin-top: 15px;
}
.deptSpandiv {
  float: left;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border:1px #ddd solid;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  margin-right: 10px;
}
.deptnamespan {
  font-size: 12px;
  line-height: 20px;
  float: left;
}
.deptcountspan {
  font-size: 12px;
  line-height: 20px;
  float: right;
}
.rolebody {
  height: 280px;
  overflow: auto;
}
/* =======================选择人员 end   =======================*/
/* =======================选择人员 end   =======================*/
.approval-process {
  overflow: auto;
  height:calc(100% - 80px)!important;
}
.main_dom {
  width: 100%;
  min-height: 150px;
}
.list {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  padding: 10px 20px;
  .item {
    width: 150px;
    height: 80px;
    background-color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    position: relative;
    .header-0 {
      height: 24px;
      background-color: #576a95;
      color: #fff;
      font-size: 12px;
      line-height: 24px;
      padding-left: 10px;
    }
    .header-1 {
      height: 24px;
      background-color: #ff943e;
      color: #fff;
      font-size: 12px;
      line-height: 24px;
      padding-left: 10px;
    }
    .header-2 {
      height: 24px;
      background-color: #3296fa;
      color: #fff;
      font-size: 12px;
      line-height: 24px;
      padding-left: 10px;
    }
    .mid {
      padding: 10px;
    }
  }
  .item_close {
    width: 5px;
    height: 5px;
    position: absolute;
    right: 20px;
    top: 0;
    color: #fff;
  }
  .item_copyV {
    position: absolute;
    right: 10px;
    bottom: 0;
  }
  .item_copyX {
    position: absolute;
    right: 30px;
    bottom: 0;
  }
  .item_setForm {
    position: absolute;
    right: 50px;
    bottom: 0;
  }
  .createDom {
    width: 160px;
    height: 180px;
    background-color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    .header {
      width: 100%;
      height: 10px;
      background-color: #ff943e;
    }
  }
  .line {
    width: 100px;
    height: 1px;
    background-color: #ccc;
    align-items: center;
    position: relative;
    .add {
      position: absolute;
    }
  }
  .item:hover {
    width: 148px;
    height: 78px;
    border: 1px solid #1890ff;
  }
  .end {
    width: 50px;
    height: 50px;
    background-color: #ccc;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    line-height: 50px;
  }
}
.position {
  position: relative;
}
.top_header {
  width: 100%;
  height: 50px;
  background-image: linear-gradient(to right, orange, orange);
}
.mid_view{
  width:100%;
  height: 100px;
}
.main_view{
.item{
    position: relative;
    .desc{
       position: absolute;
       bottom:15px;
       left:45px;
    }
  }
}
.test_pop_view {
  .one {
    width: 100%;
    height: 50px;
    background: red;
    border-radius: 10px;
  }
  .one_a {
    background-image: linear-gradient(to right, orange, orange);
  }
  .one_b{
     background-image: linear-gradient(to right, gray, gray);
  }
}
</style>
